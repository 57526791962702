'use client';
import { Provider } from 'react-redux';
import { store } from '@lib/redux/store';
import { useCallback, useEffect } from 'react';
import { Provider as RollbarProvider, ErrorBoundary, useRollbar } from '@rollbar/react';
import { SocketEventsProvider } from '@cable/socket-provider';
import { useEventSubscription } from '@cable/hook/use-event-subscription';
import CongratulationsModal from '@challenge/component/congrat-modal';
import { useRouter, useSearchParams } from 'next/navigation';
import {
  closeModal as closeCongratModal,
  setChallengeCompleted,
} from '@lib/redux/challenge/challenge';
import { useAppSelector, useAppDispatch } from '@lib/redux/store';
import { ChallengeEventPayload } from '@cable/types';
import { LoaderProvider, useLoader } from '@ui/contexts';
import { userApi } from '@lib/redux/api/user-api';
import { cookieService } from '@lib/cookie.service';
import { QUERIES } from '@constants/query.constants';
import { ROUTES } from '@constants/routes.constants';

const rollbarConfig =
  process.env['NODE_ENV'] !== 'development'
    ? {
        accessToken: process.env['NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN'],
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
          environment: process.env['NEXT_PUBLIC_ENVIRONMENT'],
          client: {
            javascript: {
              source_map_enabled: true,
              code_version: '1.0.1',
              guess_uncaught_frames: true,
            },
          },
        },
      }
    : {};

function ChallengeModal() {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { isModalOpen, challengeMessage, currentChallengeId } = useAppSelector(
    (state) => state.challenge,
  );

  const handleChallengeCompleted = useCallback(
    (payload: ChallengeEventPayload) => {
      dispatch(
        setChallengeCompleted({
          challengeId: payload.challenge_id,
          challengeName: payload.challenge_name,
          badgeId: payload.badge_id,
        }),
      );
    },
    [dispatch],
  );

  useEventSubscription<ChallengeEventPayload>('challenge_completed', handleChallengeCompleted);

  const handleCloseCongratsModal = () => {
    dispatch(closeCongratModal());
  };

  const handleViewBadge = () => {
    if (!currentChallengeId) return;
    router.push(
      `/creator/challenges/challenge/completed/?slug=${currentChallengeId}&ylap_mina=true`,
    );
  };

  return (
    <CongratulationsModal
      isOpen={isModalOpen}
      onClose={handleCloseCongratsModal}
      challengeName={challengeMessage}
      onViewBadge={handleViewBadge}
    />
  );
}

function GlobalSocketProvider({ children }: { children: React.ReactNode }) {
  const { user } = useAppSelector((state) => state.user);

  if (!user?.id) {
    return <>{children}</>;
  }

  return (
    <SocketEventsProvider userId={user.id}>
      <ChallengeModal />
      {children}
    </SocketEventsProvider>
  );
}

function CookieProvider({ children }: { children: React.ReactNode }) {
  const searchParams = useSearchParams();
  const switch_token = searchParams.get('switch_token');
  const { openLoader, closeLoader } = useLoader();
  const dispatch = useAppDispatch();
  const router = useRouter();

  useEffect(() => {
    if (switch_token) {
      const handleSwitchToken = async () => {
        try {
          openLoader();
          const parsedLogOnData = cookieService.decryptAuthData(switch_token);
          cookieService.setAuthCookies(parsedLogOnData);
          dispatch(userApi.util.invalidateTags([QUERIES.user]));
          router.push(ROUTES.creators);
        } catch (error) {
          console.error('Switch account error:', error);
          closeLoader();
        } finally {
          closeLoader();
        }
      };
      handleSwitchToken();
    }
  }, [switch_token]);

  return <>{children}</>;
}

export function Providers({ children }: React.PropsWithChildren<{}>) {
  return (
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary>
        <LoaderProvider>
          <Provider store={store}>
            <CookieProvider>
              <GlobalSocketProvider>{children}</GlobalSocketProvider>
            </CookieProvider>
          </Provider>
        </LoaderProvider>
      </ErrorBoundary>
    </RollbarProvider>
  );
}

export function useLogErrorToRollbar() {
  const rollbar = useRollbar();

  const logError = useCallback(
    (errorDetails: any, level: string = 'error') => {
      if (errorDetails) {
        rollbar[level]('Error Page Rendered', {
          ...errorDetails,
          stackTrace: errorDetails.error?.stack,
          componentStack: errorDetails.error?.componentStack,
          additionalContext: {
            url: window.location.href,
            previousUrl: document.referrer,
            browserInfo: {
              userAgent: navigator.userAgent,
              language: navigator.language,
            },
          },
        });
      }
    },
    [rollbar],
  );

  return logError;
}
